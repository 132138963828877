import React, { useState, useEffect } from 'react';

const Arcade = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setCount((c) => c + 1);
    }, 200);
  }, []);

  return (
    <div>
      <h1>Clicked {count}</h1>
      <button type="button" onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
};

export default Arcade;
